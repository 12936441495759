import React, { useState, useEffect } from 'react';
import { GetAllPayments, UpdateUserVspStatus } from '../../features/constants/apis'; // Correct API functions
import { Row, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import DataTable, { createTheme } from 'react-data-table-component';
import { connect } from 'react-redux';
import Header from '../layout/Header/Header';

const Payments = ({ authToken }) => {
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await GetAllPayments(authToken); // Assuming API returns the 'data' object with users
                setAllUsers(response.data);  // Set the users data from the response
                console.log("payments data: ", response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [authToken]);

    // Function to handle VSP status update for a user

    const handleUpdateVspStatus = async (userID) => {
        const currentUser = allUsers.find((user) => user.ID === userID); // Find the current user
        if (!currentUser) return;

        const currentStatus = currentUser.VspStatus; // Get the current VspStatus
        const newStatus = !currentStatus; // Toggle the status

        try {
            setLoading(true);
            await UpdateUserVspStatus(authToken, userID); // Call the API to update the status

            // Update the local state to reflect the new status
            setAllUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.ID === userID ? { ...user, VspStatus: newStatus } : user
                )
            );

            setShowModal(false); // Close the modal after the update
        } catch (error) {
            console.error('Error updating VSP status:', error);
        } finally {
            setLoading(false); // End the loading state
        }
    };



    const handelOpenModal = (ID, currentStatus) => {
        setSelectedUser({ ID: ID, VspStatus: currentStatus });
        setShowModal(true);
    };
    const handelCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    }

    const desiredColumns = [
        {
            name: 'ID',
            selector: (row) => row.ID, // Get the ID directly
            width: '100px',
            sortable: true,
        },
        {
            name: 'User_ID',
            selector: (row) => row.User_ID, // Correctly map FullName
            width: '250px',
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.Status, // Correctly map Phone number
            width: '260px',
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: (row) => row.TotalAmount,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Order ID',
            selector: (row) => row.OrderID,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'payment Status Code',
            selector: (row) => row.paymentStatusCode,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Payment Success Response',
            selector: (row) => row.PaymentSuccessResponse,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Payment Fail Response',
            selector: (row) => row.PaymentFailResponse,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Payment Method',
            selector: (row) => row.PaymentMethod,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        // {
        //     name: 'Payment Response',
        //     selector: (row) => row.FullName,
        //     width: '180px',
        //     wrap: true,
        //     sortable: true,
        // },
        {
            name: 'Currency Code',
            selector: (row) => row.CurrencyCode,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        // {
        //     name: 'Notes',
        //     selector: (row) => row.FullName,
        //     width: '180px',
        //     wrap: true,
        //     sortable: true,
        // },
        {
            name: 'Payment Card Type',
            selector: (row) => row.PaymentCardType,
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Applied To',
            selector: (row) => row.AppliedTo,
            width: '180px',
            wrap: true,
            sortable: true,
        },

        // {
        //     name: 'Edit',
        //     selector: (row) => (
        //         <Button
        //             variant={row.VspStatus ? 'outline-primary' : 'primary'}
        //             size="sm"
        //             onClick={() => handelOpenModal(row.ID)}
        //             // disabled={row.VspStatus}
        //         >
        //             {row.VspStatus ? 'Verified' : 'Pending'}
        //         </Button>
        //     ),
        //     width: '120px',
        //     margin:"20px"
        // },

    ];

    createTheme(
        'solarized',
        {
            background: {
                default: 'transparent',
            },
            context: {
                background: '#CDFFD8',
                text: '#FFFFFF',
            },
            divider: {
                default: '#45A994',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        },
        'light'
    );

    return (
        <>
            <Header />
            <div id="appCapsule">
                <div className="section full py-4 mx-5 px-3">
                    <div className="container-fluid">
                        <Button variant="primary mb-2 ">
                            <Link to="/" className="my-2 text-white">
                                <FaArrowLeft style={{ width: '15px', height: '15px', marginRight: '7px' }} />
                                Go to Dashboard
                            </Link>
                        </Button>
                        <h1 className="title text-dark ">Payments</h1>
                    </div>
                </div>
                <div className="section full mt-2 mb-4">
                    <div className="container-fluid">
                        <Row className="justify-content-between my-3 mx-5">
                            <div>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <DataTable
                                        className='justify-content-center'
                                        columns={desiredColumns}
                                        data={allUsers}
                                        striped={true}
                                        theme="solarized"
                                    />
                                )}
                            </div>
                        </Row>
                    </div>
                </div>
            </div>

            {/* confirmation modal is wriiten here  */}
            <Modal show={showModal} onHide={handelCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm VSP Status Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are your Sure you want to{selectedUser?.VspStatus ? ' mark this user as Pending' : 'verify this user for VSP'}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handelCloseModal}>Cancel</Button>
                    <Button variant='primary' onClick={() => handleUpdateVspStatus(selectedUser?.ID)}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ auth }) => {
    const { authToken } = auth;
    return {
        authToken,
    };
};

export default connect(mapStateToProps)(Payments);
